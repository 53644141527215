<template>
  <div class="banner-list-item">
    <b-card
      no-body
      class="mb-3"
    >
      <div class="d-flex">
        <!-- Image Preview Section -->
        <div class="banner-image">
          <b-img
            :src="image.image"
            :alt="image.alt_text || image.title"
            fluid
            class="banner-thumbnail"
          />
        </div>

        <!-- Image Details Section -->
        <div class="banner-details px-2 flex-grow-1">
          <!-- Header with title and badges -->
          <div class="d-flex justify-content-between align-items-start mb-2">
            <h5 class="mb-0">
              {{ image.title || 'Untitled' }}
            </h5>
            <div>
              <b-badge
                :variant="image.is_active ? 'success' : 'warning'"
                class="mr-2"
              >
                {{ image.is_active ? 'Active' : 'Inactive' }}
              </b-badge>
              <b-badge variant="info">
                {{ formatImageType(image.image_type) }}
              </b-badge>
            </div>
          </div>

          <!-- Image Details -->
          <div class="banner-info mb-2">
            <div><small><strong>ID:</strong> {{ image.id }}</small></div>
            <div><small><strong>Alt Text:</strong> {{ image.alt_text || 'None' }}</small></div>
            <div><small><strong>Order:</strong> {{ image.order }}</small></div>
            <div v-if="image.url_link">
              <small><strong>URL:</strong> {{ image.url_link }}</small>
            </div>
            <div>
              <small>
                <strong>Created:</strong> {{ formatDate(image.created_at) }}
                | <strong>Updated:</strong> {{ formatDate(image.updated_at) }}
              </small>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="d-flex">
            <b-button
              size="sm"
              variant="outline-primary"
              class="mr-2"
              :disabled="processing"
              @click="editImage"
            >
              <b-icon icon="pencil" /> Edit
            </b-button>

            <b-button
              size="sm"
              :variant="image.is_active ? 'outline-warning' : 'outline-success'"
              class="mr-2"
              :disabled="processing"
              @click="toggleActive"
            >
              <b-icon :icon="image.is_active ? 'eye-slash' : 'eye'" />
              {{ image.is_active ? 'Deactivate' : 'Activate' }}
            </b-button>

            <b-button
              size="sm"
              variant="outline-danger"
              :disabled="processing"
              @click="confirmDelete"
            >
              <b-icon icon="trash" /> Delete
            </b-button>
          </div>
        </div>
      </div>
    </b-card>

    <!-- Edit Modal -->
    <b-modal
      v-model="showEditModal"
      title="Edit Image"
      ok-title="Save"
      @ok="saveEdit"
    >
      <b-form>
        <b-form-group label="Title">
          <b-form-input v-model="editForm.title" />
        </b-form-group>

        <b-form-group label="Alt Text">
          <b-form-input v-model="editForm.alt_text" />
        </b-form-group>

        <b-form-group
          label="Image Type"
          label-for="image_type"
        >
          <b-form-select
            id="image_type"
            v-model="editForm.image_type"
            required
            :options="imageTypeOptions"
          />
        </b-form-group>

        <b-form-group label="Order">
          <b-form-input
            v-model="editForm.order"
            type="number"
          />
        </b-form-group>

        <b-form-group
          v-if="image.url_link !== null"
          label="URL Link"
        >
          <b-form-input v-model="editForm.url_link" />
        </b-form-group>

        <b-form-group>
          <b-form-checkbox
            v-model="editForm.is_active"
            switch
          >
            Active
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Delete Confirmation Modal -->
    <b-modal
      v-model="showDeleteModal"
      title="Delete Image"
      ok-variant="danger"
      ok-title="Delete"
      @ok="deleteImage"
    >
      <p>Are you sure you want to delete this image?</p>
      <p class="text-danger">
        This action cannot be undone.
      </p>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import {
  BCard, BFormGroup, BModal, BForm, BFormCheckbox, BIcon, BButton, BFormInput,
  BBadge,
  BImg,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BModal,
    BForm,
    BFormCheckbox,
    BIcon,
    BButton,
    BFormInput,
    BBadge,
    BImg,
    BFormSelect,
  },
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      showEditModal: false,
      showDeleteModal: false,
      editForm: {
        title: '',
        alt_text: '',
        order: 0,
        image_type: 'banner_image',
        is_active: true,
        url_link: '',
      },
      imageTypeOptions: [
        { value: 'banner_image', text: 'Banner Image' },
        { value: 'partner_company_logo', text: 'Partner Company Logo' },
      ],
    }
  },
  methods: {
    formatImageType(type) {
      return type === 'banner_image' ? 'Banner' : 'Partner Logo'
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A'
      const date = new Date(dateString)
      return date.toLocaleDateString()
    },
    editImage() {
      this.editForm = {
        title: this.image.title || '',
        alt_text: this.image.alt_text || '',
        order: this.image.order || 0,
        is_active: this.image.is_active,
        image_type: this.image.image_type || 'banner_image',
        url_link: this.image.url_link || '',
      }
      this.showEditModal = true
    },
    async saveEdit() {
      this.processing = true
      try {
        await axios.patch(`/retailers/images/${this.image.id}/`, this.editForm)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Image updated successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('refresh')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to update image. Please try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.processing = false
      }
    },
    async toggleActive() {
      this.processing = true
      try {
        await axios.patch(`/retailers/images/${this.image.id}/`, {
          is_active: !this.image.is_active,
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Image ${!this.image.is_active ? 'activated' : 'deactivated'} successfully`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('refresh')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to update image. Please try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.processing = false
      }
    },
    confirmDelete() {
      this.showDeleteModal = true
    },
    async deleteImage() {
      this.processing = true
      try {
        await axios.delete(`/retailers/images/${this.image.id}/`)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Image deleted successfully!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('refresh')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to delete image. Please try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.processing = false
      }
    },
  },
}
</script>

<style scoped>
.banner-list-item {
  margin-bottom: 15px;
}

.banner-image {
  width: 300px;
  min-width: 150px;
  height:150px;
  min-height: 150px;
  overflow: hidden;
  object-fit: cover;
  background-color: #f8f9fa;
}

.banner-thumbnail {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.banner-info {
  font-size: 0.9rem;
}
</style>
