<template>
  <div class="image-upload-form border-primary rounded m-1 p-2">
    <h2>Upload Image</h2>
    <b-form
      enctype="multipart/form-data"
      @submit.prevent="submitForm"
    >
      <!-- First row with three inputs -->
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="formData.title"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Alt Text"
            label-for="alt_text"
          >
            <b-form-input
              id="alt_text"
              v-model="formData.alt_text"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Image For"
            label-for="image_type"
          >
            <b-form-select
              id="image_type"
              v-model="formData.image_type"
              required
              :options="imageTypeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Second row with file upload -->
      <b-row>
        <b-col>
          <b-form-group
            label="Image"
            label-for="image"
          >
            <b-form-file
              id="image"
              v-model="imageFile"
              accept="image/*"
              placeholder="Choose an image or drop it here..."
              drop-placeholder="Drop image here..."
              required
              @input="handleFileUpload"
            />
          </b-form-group>

          <div
            v-if="imagePreview"
            class="image-preview mt-2"
          >
            <b-img
              :src="imagePreview"
              alt="Preview"
              fluid
              thumbnail
            />
          </div>
        </b-col>
      </b-row>

      <!-- Third row with switch and buttons -->
      <b-row class="mt-1">
        <b-col cols="6">
          <b-form-group>
            <b-form-checkbox
              id="is_active"
              v-model="formData.is_active"
              switch
            >
              Active
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col
          cols="6"
          class="text-right"
        >
          <b-button
            type="submit"
            variant="primary"
            :disabled="isSubmitting"
            class="mr-2"
          >
            {{ isSubmitting ? 'Uploading...' : 'Upload Image' }}
          </b-button>

          <b-button
            type="button"
            variant="secondary"
            @click="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

      <!-- Fourth row with alerts -->
      <b-row class="mt-1">
        <b-col>
          <b-alert
            v-if="errorMessage"
            show
            variant="danger"
          >
            {{ errorMessage }}
          </b-alert>

        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import {
  BAlert, BCol, BRow, BFormInput, BForm, BButton, BImg, BFormGroup, BFormCheckbox, BFormFile, BFormSelect,
} from 'bootstrap-vue'

export default {
  name: 'ImageUploadForm',
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BButton,
    BImg,
    BFormFile,
    BFormSelect,
  },
  data() {
    return {
      formData: {
        title: '',
        alt_text: '',
        image_type: 'banner_image',
        is_active: true,
      },
      imageFile: null,
      imagePreview: null,
      isSubmitting: false,
      errorMessage: '',
      successMessage: '',
      imageTypeOptions: [
        { value: 'banner_image', text: 'Banner Image' },
        { value: 'partner_company_logo', text: 'Partner Company Logo' },
      ],
    }
  },
  methods: {
    handleFileUpload() {
      if (this.imageFile) {
        // Create image preview
        const reader = new FileReader()
        reader.onload = e => {
          this.imagePreview = e.target.result
        }
        reader.readAsDataURL(this.imageFile)
      } else {
        this.imagePreview = null
      }
    },
    async submitForm() {
      if (!this.imageFile) {
        this.errorMessage = 'Please select an image file'
        return
      }

      this.isSubmitting = true
      this.errorMessage = ''
      this.successMessage = ''

      try {
        // Create FormData object for file upload
        const formData = new FormData()
        formData.append('image', this.imageFile)
        formData.append('title', this.formData.title)
        formData.append('alt_text', this.formData.alt_text)
        formData.append('image_type', this.formData.image_type)
        formData.append('is_active', this.formData.is_active)

        // Send request to API
        const response = await axios.post('/retailers/images/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message || 'Image uploaded successfully!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('fetchItemsAfterSave')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message || 'Failed to upload image. Please try again.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isSubmitting = false
        this.resetForm()
      }
    },
    resetForm() {
      this.formData = {
        title: '',
        alt_text: '',
        image_type: 'banner_image',
        is_active: true,
      }
      this.imageFile = null
      this.imagePreview = null

      // Reset file input
      const fileInput = document.getElementById('image')
      if (fileInput) {
        fileInput.value = ''
      }
    },
  },
}
</script>

<style scoped>
.image-preview {
  margin-top: 10px;
  border: 1px solid #ddd;
  padding: 5px;
  max-width: 100%;
}

.image-preview img {
  max-width: 100%;
  max-height: 200px;
}
</style>
