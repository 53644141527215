<template>
  <b-card no-body>
    <ImageForm @fetchItemsAfterSave="fetchItems" />
    <b-alert
      v-if="loadingError"
      show
      variant="danger"
      dismissible
    >
      {{ loadingError }}
    </b-alert>

    <b-tabs
      pills
      card
    >
      <b-tab
        title="Banners"
        active
      >
        <BannerListItem
          v-for="image in bannerImages"
          :key="image.id"
          :image="image"
          @refresh="fetchItems"
        />
      </b-tab>
      <b-tab title="Partner Logo">
        <h1>Tab contents 2</h1>
        <CompanyLogoItems
          v-for="image in companyLogoImages"
          :key="image.id"
          :image="image"
          @refresh="fetchItems"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import axios from 'axios'
import ImageForm from '@/components/Retailers/Banners/ImageForm.vue'
import {
  BCard, BTab, BTabs, BAlert,
} from 'bootstrap-vue'
import BannerListItem from '@/components/Retailers/Banners/BannerListItem.vue'
import CompanyLogoItems from '@/components/Retailers/Banners/CompanyLogoItems.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    ImageForm,
    BAlert,
    BannerListItem,
    CompanyLogoItems,
  },
  data() {
    return {
      companyLogoImages: [],
      bannerImages: [],
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    async fetchItems() {
      this.loading = true
      this.loadingError = null // Reset loading error
      try {
        const { data } = await axios.get('/retailers/images')
        this.bannerImages = data.filter(item => item.image_type === 'banner_image')
        this.companyLogoImages = data.filter(item => item.image_type === 'partner_company_logo')
      } catch (error) {
        this.loadingError = error?.response?.data?.error || 'Something went wrong'
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
